body {
  margin: 0;
   background-color: rgba(241, 243, 244, 0.431);
  font-family: "Open Sans", system-ui;
}
html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.highlight {
  animation: fadeIn 0.5s ease forwards;
  background: linear-gradient(to right, #2f47bb 0%, #3280b8 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  padding: 0 5%;
  font-size: 2rem;
}

.highlight3 {
  background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=a1ff43);
}
h2 {
  line-height: 1.5;
  font-size: 1.5rem;
  font-weight: 300;
  text-wrap: balance;
  letter-spacing: 0.5px;
  text-align: center;
}
h5{
  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: 300;
  text-wrap: balance;
  letter-spacing: 0.5px;
  text-align: center;
  padding: 0 5%;
}
/*logo*/
.navbar img {
  width: 100px;
  height: 100px;
  padding: 20px;
}
/*footer*/
.footer {
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgb(0, 0, 0);
  text-align: center;
}

/*textarea*/
.textareagrid {
  grid-column: 1;
  position: relative;
}
textarea {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin: 5px auto;
  justify-content: center;
  resize: none;
  padding: 11px;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 1rem;
  outline: none;
  transition: 0.5s ease;
  font-size: large;
}

/*form*/
.contactForm input {
  font-size: large;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid rgb(191, 191, 191);
  border-radius: 1rem;
  transition: 0.5s ease;
  outline: none;
}
.contactForm input:focus,
textarea:focus {
  border: 1px solid rgb(13, 236, 13);
}
.contactForm label {
  font-size: 1.3rem;
  display: block;
  flex-direction: column;
  margin-bottom: 16px;
  font-weight: 400;
}
form {
  animation: fadeIn 0.8s ease-out forwards;
  border-radius: 0.5rem;
  margin: 0 7%;
  border-radius: 1rem;
  padding: 3%;
}
fieldset {
  display: grid;
  grid-template-columns: 280px 250px 250px;
  gap: 10px;
  margin: auto;
  width: 100%;
  max-width: 300px;
  border: none;
}

.butonTrimite {
  display: block;
  margin: 0 auto;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 1rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.butonTrimite:hover {
  background-color: #0056b3;
}

.butonTrimite:active {
  transform: scale(0.95);
}

/*navbar*/
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 5px;
 
}

.button-container{
  display: flex;
  gap: 5px;
  
}

.nav1{
  display: flex;
  width: 50%;
 align-items: center;

}
.nav2{
  
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  
  
}

/*content*/

@keyframes fadeIn {
  from {
    translate: -50px;
    opacity: 0; /* Start with opacity 0 */
  }
  to {
    translate: 0;
    opacity: 1; /* Fade in to full opacity */
  }
}
.mainContent .p1 {
  margin: 8% 3%;
  padding: 3%;
  border-radius: 1rem;
}
.mainContent {
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
  text-align: center;
  padding: 0 5%;
  font-size: 2.3rem;
  text-wrap: balance;
  line-height: 1.5;
  letter-spacing: 0.5px;
}

sup {
  color: red;
}


@keyframes flash {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}




.scroll-button {
  position: fixed;
  bottom: 20px;
  left: 0px;
  color:#007bff;
  background-color: transparent;
  border: none;
  padding:10px 10px;
  font-size: 35px;
  cursor: pointer;
  border-radius: 1rem;
  z-index: 1000;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;


}




.sucessMessage {
  text-align: center;
  font-size: 20px;
  color: green;
}

.error {
  color: red;
  font-size: 1rem;
  font-weight: 300;
  
}
/*social Icons*/
.fIcon {
  width: 50px;
  height: 50px;
  color: rgb(45, 105, 209);
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  z-index: 1000;
  
}

.fIcon:hover {
  color: rgb(102, 152, 238);
}
.wIcon {
  width: 50px;
  height: 50px;
  color: rgb(45, 209, 45);
  cursor: pointer;
  transition: 0.3s;
  
  z-index: 1000;
  animation: flash 2s infinite; /* Apply flashing animation */

}
.wIcon:hover {
  color: rgb(49, 240, 20);
}


/* Media Queries for 600px and below */
@media (max-width: 600px) {
  .contactForm label,
  textarea,
  .contactForm input {
    width: 95%; /* Take most of the screen width */
  }

  fieldset {
    width: 100%; /* Adjust fieldset width */
    grid-template-columns: 1fr; /* Make form full-width */
  }

  .footer {
    padding: 20px; /* Add more padding for mobile */
    text-align: center;
    flex-direction: column; /* Stack content vertically on small screens */
  }

  * {
    animation: none;
  }

  .fIcon,
  .wIcon {
    width: 8vw; /* Slightly smaller for very small screens */
    height: 8vw;
  }

  h2 {
    font-size: 1.2rem;
  }
  h5{
    font-size: 0.8rem;
  }

  .highlight {
    font-size: 1.2rem; /* Reduce highlight text size */
  }

  .mainContent {
    font-size: 1.8rem; /* Adjust text size */
    padding: 0 3%; /* Reduce padding for small screens */
  }

  .navbar img {
    width: 80px;
    height: 80px; /* Smaller logo for tiny screens */
  }
}
